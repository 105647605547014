import React, { Component } from 'react';

import "styles/infobox.scss";

import CrossIcon from "assets/icons/btn_close.svg";
import DarkCrossIcon from "assets/icons/btn_close_dark.svg";

class Infobox extends Component {
    state = {  }
    
    renderContent = () => {
        return (
            <div className="content">
                <h1>Le Projet PEPS</h1>
                <p className="headline">
                    La direction de Médicina a souhaité nous associer aux missions qu'elle s'est données en relation avec la crise sanitaire du Covid-19 :
                </p>
                <ol className="list">
                    <li>Accompagner et soutenir les acteurs de la Santé confrontés à une situation stressante inédite,</li>
                    <li>Comprendre les raisons de ce stress,</li>
                    <li>Planifier et développer des outils de gestion du stress des acteurs de la Santé dans l'éventualité de crises futures,</li>
                    <li>Obtenir un retour d'expérience transmissible aux autorités de Santé.</li>
                </ol>
                <p>
                    Pour cela, nous avons mis en place une plateforme d'échanges sécurisés et anonymisés entre la direction et l'ensemble des acteurs de la Santé salariés ou non, soignants ou non.
                    <br />
                    <br />
                    Cette plateforme reccueillera vos réponses à 2 questionnaires*, dont un sera réitéré à intervalles réguliers.
                </p>
                <p className="info">
                    *Le premier relève l'Exposition aux Facteurs de Risque du Stress (EFRS).
                    Les résultats obtenus donneront lieu à un retour descriptif dans les semaines à venir.
                    Ce questionnaire, dont Médicina est partenaire, fait l'objet d'une démarche de validation complète (bibliographique, interne, externe et statistique).
                    Les personnes souhaitant participer à cette validation peuvent se manifester auprès de la direction de Médicina.
                    À la fin de cette démarche, nous souhaitons faire ressortir les facteurs uni-ou-pluri-dimensionnelles en relation avec le stress collectif ressenti chez Médicina.
                    L'objectif est de publier un questionnaire court, simple d'utilisation et prédictif de la survenue du stress dans des équipes de Santé.
                </p>
                <p className="headline">
                    En parallèle, vous serez régulièrement invités à remplir un court questionnaire validé - le MSP-9 - qui sera le baromètre du stress collectif des acteurs de Médicina. Il permettra à la direction de mettre en place des actions pour améliorer votre Santé au travail.
                </p>
                <p>
                    Nous pensons qu'il est important de rappeler, même s'il s'agit d'une évidence, que les données reccueillies ne seront pas traitées individuellement et qu'aucune donnée nominative ne sera accessible par qui que ce soit : vous, la direction de Médicina ou Plaïad. L'ensemble des données fera l'objet d'un hébergement anonymisé répondant aux dispositions réglementaires en matière de protection des données de santé.
                </p>
                <div className="space"></div>
            </div>
        )
    }

    renderContact = () => {
        return (
            <div className="info-contact">
                <div>
                    <h2>Vous avez des questions ?</h2>
                    <button className="btn btn-main" onClick={() => {
                        document.location.href = "mailto:hello@kiplite.com";
                    }}>Nous contacter</button>
                </div>
            </div>
        )
    }

    renderFoot = () => {
        return (
            <div className="info-foot">
                <a href="/MENTIONS_LEGALES.html" target="_blank">MENTIONS LÉGALES</a><span>|</span>
                <a href="/COOKIES.html" target="_blank">COOKIES</a><span>|</span>
                <a href="/CONFIDENTIALITES.html" target="_blank">CONFIDENTIALITÉ</a>
                <p>Copyright © 2020 kiplite expertise</p>
            </div>
        )
    }
    
    render() { 
        return (
            <div className={"info-box-wrapper " + (this.props.opened ? "active" : "")}>
                <div className="info-box">
                    <this.renderContent />
                    <this.renderContact />
                    <this.renderFoot /> 
                </div>
                <button className="close-btn dark" onClick={this.props.onClose}><img src={DarkCrossIcon} alt="X"/></button>
                <button className="close-btn white" onClick={this.props.onClose}><img src={CrossIcon} alt="X"/></button>
            </div>
        );
    }
}

export default Infobox;