import React, { Component } from 'react';

class Textbox extends Component {
    
    constructor(props)
    {
        super(props);
        
        this.state = {
            focused: false,
            type: props.type ? props.type : "text"
        }

        this.input = React.createRef();
    }

    onFocus = e => {
        this.setState({focused: true});
        this.props.onFocus(e.target);
    }
    
    onBlur = e => {
        if (!this.state.focused)
            return;
        this.setState({focused: false});
        this.props.onValidated(e.target);
    }
    
    onKeyDown = e => {
        if (this.state.focused && e.key === 'Enter')
            e.target.blur();
    }

    onClick = e => {
        if (this.input)
            this.input.current.focus();
    }

    render() {
        return (
            <div 
                className={"tb " + (this.props.className) + (this.state.focused ? " focused" : "")} 
                onKeyDown={this.onKeyDown}
                onFocus={this.onFocus} 
                onBlur={this.onBlur}
                onClick={this.onClick}
                onTouchStart={this.onClick}
                >
                <span className="tb-text">
                    {this.props.data.otherText}
                </span>
                <input ref={this.input} type={this.state.type} placeholder="Précisez" value={this.props.value} onChange={e => {this.props.onChange(e.target)}}/>
            </div>
        );
    }
}

export default Textbox;