import React from 'react';
import Logo from "assets/logos/medicina-logo-w.svg";

export default function StartView(props) {
    return (
        <div className={"view bg forground"}>
            <div className="container">
                <img src={Logo} alt="logo" className="logo"/>
                <h1>{props.data.title}</h1>
                <h2>{props.data.subtitle}</h2>
                {props.data.text.length > 1 ? props.data.text.map((v, i) => (<h3>{v}</h3>)) : <h3>{props.data.text}</h3>}
                <button className="btn btn-main maxwidth" onClick={props.onNext}>{props.data.start}</button>
            </div>
        </div>
    );
}