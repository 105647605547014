import React, { useState } from 'react';
import Database from 'api/Database';
import crypto from "crypto";
import axios from "axios";

export default function SendView(props) {

    const [sending, setSending] = useState(false);

    const sendResults = e => {
        setSending(true);
        let results = {
            qcm: props.qcm,
            uid: props.uid,
            time: Date.now(),
            results: props.results
        };

        const jsonResults = JSON.stringify(results);
        const aid = crypto.createHash('md5').update(Date.now() + "-" + props.uid).digest("hex");
        console.log(jsonResults);

        let bodyFormData = new FormData();
        bodyFormData.set('jsonString', jsonResults);

        Database.getData("contacts", props.uid)
            .then(e => {
                return Database.setToCollection("answers", aid, {
                    uid: props.uid,
                    cid: props.cid,
                    contact: e.data(),
                    // results: props.results
                })
            }).then(e => {
                console.log("FB: OK !");
                if (props.uid === "BXT7T1kv51f89rMBm5je")
                {
                    return;
                }
                return axios.post(process.env.REACT_APP_API_HOST + "/Peps/Peps_Record", {
                    jsonString: jsonResults
                })
            }).then(e => {
                console.log(e);
                console.log("PEPS: OK !");
                props.onSend();
            })
            .catch(e => {
                console.error(e);
            });
    }

    return (
        <div className={"view"}>
            <div className="send">
                <div className="container">
                    <button className={"btn btn-main " + (sending ? "selected" : "")} onClick={sendResults}>Envoyer</button>
                </div>
            </div>
        </div>
    );
}
