import { db } from "../Firebase";

class Database
{
    updateCollection(collection, doc, data)
    {
        return db.collection(collection).doc(doc).update(data);
    }

    setToCollection(collection, doc, data)
    {
        return db.collection(collection).doc(doc).set(data);
    }

    getData(collection, doc)
    {
        return db.collection(collection).doc(doc).get();
    }

    getDataRealtime(collection, doc, callback)
    {
        return db.collection(collection).doc(doc).onSnapshot(callback);
    }

    getAllDataRealtime(collection, callback)
    {
        return db.collection(collection).onSnapshot(callback);
    }

    getAllData(collection)
    {
        return db.collection(collection).get();
    }
    
    getDataWhereUID(collection, uid)
    {
        return db.collection(collection).where("uid", "==", uid).get();
    }

    getDataWhere(collection, field, op, value)
    {
        return db.collection(collection).where(field, op, value).get();
    }
}

export default new Database();