import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import StartView from './components/views/StartView';
import QuestionView from './components/views/questions/QuestionView';
import SendView from './components/views/SendView';
import FinishView from './components/views/FinishView';
import Infobox from './components/Infobox';

import UpIcon from 'assets/icons/up.svg';
import DownIcon from 'assets/icons/down.svg';
import HelpIcon from 'assets/icons/btn_help.svg';

class MainView extends Component {
    constructor(props)
    {
        super(props);

        this.viewContainer = React.createRef();
        this.vid = 0;
        this.switchingViewTime = 700;
        this.switchingView = false;

        // console.log(process.env.REACT_APP_QCM)

        // Campain ID
        // this.cid = this.props.match.params.cid ? this.props.match.params.cid : (process.env.REACT_APP_QCM ? process.env.REACT_APP_QCM : 3);  
        this.cid = this.props.match.params.cid;  
        // this.cid = 1; 
        
        this.uuid = this.props.match.params.uuid;
        this.qcm = "QCM" + this.cid;
        this.questionsArray = props.t(`${this.qcm}:questions`, { returnObjects: true });
        
        // console.log(this.questionsArray);
        
        // Question ID in this.questionArray
        this.qaid = -1;
        
        // Question ID in JSON file
        this.qid = 0;

        const views = [
            <StartView 
                key={0} 
                qcm={this.qcm} 
                data={props.t(`${this.qcm}:welcome`, { returnObjects: true })}
                visible={true} 
                onNext={() => this.nextView(200)} 
                />];
        
        this.results = {};
        this.finalResults = {};

        this.state = {
            currentView: 0,
            views: views,
            infobox: false
        }

        this.delay = Date.now();
        this.editing = false;
    }

    /**
     * @param {int} vid - Current View ID
     * @param {int} qid - Question ID in JSON file
     */
    addQuestion = (vid, qid) => {
        // console.log("Adding question");

        let views = this.state.views;
        if (views[vid])
        {
            this.setState({currentView: vid});
            return;
        }

        const question = this.questionsArray[this.qaid];
        if (!question)
        {
            this.setState({currentView: vid});
            return;
        }

        let results = undefined;

        if (this.results[this.vid] !== undefined)
            results = this.results[this.vid];

        views.push(<QuestionView 
            key={vid}  
            main={this} 
            vid={vid} 
            data={question}
            results={results}
            visible={true}
            onEditing={() => {this.editing = true}} 
            onNext={this.onNext}
            onIgnore={() => this.nextView(0)}
        />);
        this.setState({views, currentView: vid});
    }

    removingQuestion = (vid) => {
        // console.log("removing: " + vid);
        setTimeout(() => {
            let views = this.state.views;
            
            if (views[vid])
                views.splice(vid, 1);
            if (this.finalResults[vid])
                delete this.finalResults[vid];
            
            this.setState({views});
            // console.log("removed: " + vid);

        }, this.switchingViewTime);
    }

    onNext = (response, delay = 1500) => {
        this.results[this.vid] = {
            qaid: this.qaid,
            response
        };
        this.finalResults[this.vid] = response;

        this.nextView(delay);
    }

    /**
     * @param {int} vid - Current View ID
     */
    addSendView = (vid) => {
        let views = this.state.views;
        if (views[vid])
        {
            this.setState({currentView: vid});
            return;
        }
        views.push(<SendView 
            key={this.questionsArray.length + 1}
            results={this.finalResults}
            onSend={() => this.nextView(0)}
            uid={this.uuid}
            qcm={this.qcm}
            cid={this.cid}
            visible={true}
            />);
        this.setState({views, currentView: vid});
    }

    /**
     * @param {int} vid - Current View ID
     */
    addFinishView = (vid) => {
        let views = this.state.views;
        if (views[vid])
        {
            this.setState({currentView: vid});
            return;
        }
        views.push(<FinishView 
            key={this.questionsArray.length + 2} 
            qcm={this.qcm} 
            data={this.props.t(`${this.qcm}:finish`, { returnObjects: true })}
            visible={true}
            />);
        this.setState({views, currentView: vid});
    }

    /**
     * @param {int} qid - Question ID in JSON file
     */
    getQuestionByQID = (qid) => {
        let result = undefined;

        this.questionsArray.forEach((e, i) => {
            if (e.qid === qid)
                result = e;
        });
        return result;
    }

    /**
     * @param {int} qid - Question ID in JSON file
     */
    getResultByQID = (qid) => {
        let result = undefined;

        for (let [key, e] of Object.entries(this.results)) {
            void key;
            if (e.response)
                if (e.response.qid === qid)
                    result = e.response.value;
        }
        return result;
    }

    /**
     * @param {int} qid - Question ID in JSON file
     * @param {string} equal - Reponse from question {qid}
     */
    isQuestionResultEquals = (qid, equal) => {
        let result = false;
        const response = this.getResultByQID(qid);
        const equals = equal.split('|');

        equals.forEach(e => {
            if (e === response)
                result = true;
        })
        return result;
    }

    getNextValidQuestion = () => {
        if (this.qaid < this.questionsArray.length && this.qaid >= 0)
        {
            let next = this.questionsArray[this.qaid];
            if (next.if)
            {
                let valid = 0;
                next.if.forEach(e => {
                    const qid = e.qid;
                    const equals = e.equals;
    
                    if (this.isQuestionResultEquals(qid, equals))
                        valid++;
                })

                if (valid !== next.if.length)
                {
                    this.qaid++;
                    return this.getNextValidQuestion();
                }
            }
        }
        return undefined;
    }

    /**
     * @param {int} vid - Current View ID
     */
    setView = (vid) =>
    {
        this.switchingView = true;
        setTimeout(() => {
            this.switchingView = false;
        }, this.switchingViewTime);

        if (this.state.views[vid - 1]?.type === SendView)
        {
            this.addFinishView(vid);
            this.viewContainer.current.style.top = (parseInt(vid) * -100) + "%";
            return;
        }

        if (this.state.currentView < vid && vid >= 0)
        {
            if (this.qaid < this.questionsArray.length && this.qaid >= 0)
            {
                let nextQuestion = this.questionsArray[this.qaid];
                
                if (nextQuestion.if)
                {
                    let next = this.getNextValidQuestion();
                    if (next)
                        nextQuestion = next;
                }
                this.addQuestion(vid, nextQuestion.qid);
            }
            else
                this.addSendView(vid);
        }
        else
            this.setState({currentView: vid});
        this.viewContainer.current.style.top = (parseInt(vid) * -100) + "%";
    }

    getCurrentView()
    {
        return this.state.views[this.state.currentView];
    }

    /**
     * @param {int} delay - Delay before next question in ms
     */
    nextView = (delay = 0) =>
    {
        // if (this.vid + 1 >= this.state.views.length)
        //     return;
        if (this.switchingView)
            return;

        this.editing = false;
        this.delay = Date.now();
        setTimeout(() => {
            if (this.editing)
                return;

            const currDelay = Date.now();
            const elapse = currDelay - this.delay;
            
            if (elapse < delay)
                return;
            this.lastQaid = this.qaid;
            this.vid++;
            this.qaid++;
            this.setView(this.vid);
        }, delay);
    }

    /**
     * @param {int} delay - Delay before previous question in ms
     */
    prevView = (delay = 0) =>
    {
        if (this.switchingView)
            return;

        this.editing = false;
        this.delay = Date.now();
        setTimeout(() => {
            if (this.editing)
                return;

            const currDelay = Date.now();
            const elapse = currDelay - this.delay;

            if (elapse < delay)
                return;

            this.removingQuestion(this.vid);

            this.lastQaid = this.qaid;
            this.vid--;
            this.qaid = 0;
            const prevResult = this.results["" + this.vid];
            if (prevResult)
                this.qaid = prevResult.qaid;
            this.setView(this.vid);
        }, delay);
    }

    renderProgress = () => {
        return (
            <div className="progress">
                {this.questionsArray.map((e, i) => {
                    let status = "";

                    if (this.qaid === i)
                        status = "current";
                    if (this.qaid > i)
                        status = "past";

                    return <div key={i} className={"progress-block " + status}></div>;
                })}
            </div>
        )
    }

    renderControls = () => {
        const instanceOfQuestion = this.getCurrentView().type === QuestionView;

        return (
            <div className="container fullheight">
                <div className="controls">
                    <button className="prev" onClick={e => this.prevView(0)}>
                        <img src={UpIcon} alt="^" />
                    </button>
                    <button className="next" onClick={e => this.nextView(0)} disabled={!instanceOfQuestion}>
                        <img src={DownIcon} alt="v" />
                    </button>
                </div>
            </div>
        )
    }

    render() {
        const infoBtnVisible = this.getCurrentView().type !== QuestionView && this.getCurrentView().type !== SendView;
        console.log(infoBtnVisible);

        return (
            <div className="viewport">
                <div ref={this.viewContainer} className="view-container">
                    {this.state.views.map(e => e)}
                    <div className="view fixed">
                        <this.renderProgress />
                        <this.renderControls />
                    </div>
                    <Infobox opened={this.state.infobox} onClose={() => {this.setState({infobox: false})}}/>
                </div>
                {infoBtnVisible && <button className={"help-btn " + (this.state.infobox ? "hidden" : "")} onClick={() => this.setState({infobox: true})}><img src={HelpIcon} alt="?"/></button>}
            </div>
        );
    }
}

export default withTranslation()(MainView);