import React from 'react';

import OkIcon from "assets/logos/medicina-logo-w.svg";

export default function FinishView(props) {
    return (
        <div className={"view bg forground"}>
            <div className="container">
                <img src={OkIcon} alt="logo"/>
                <h1>{props.data.title}</h1>
                <h2>{props.data.subtitle}</h2>
                <h3>{props.data.text}</h3>
                <h3>{props.data.signature}</h3>
            </div>
        </div>
    );
}
