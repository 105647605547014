import QCM1 from "./qcm1.json";
import QCM2 from "./qcm2.json";
import QCM3 from "./qcm3.json";
import QCM4 from "./qcm4.json";
import QCM4242 from "./qcm4242.json";

export default {
    QCM1,
    QCM2,
    QCM3,
    QCM4,
    QCM4242
}