import React from 'react';
import { Switch, Route } from "react-router-dom";

import MainView from "./components/views/main/MainView";
import ErrorView from "./components/views/ErrorView";

import "styles/main.scss";

function App() {
  return (
    <Switch>
      {/* <Route exact path="/" component={MainView} /> */}
      <Route exact path="/medicina/:cid/:uuid" component={MainView} />
      <Route component={ErrorView} />
    </Switch>
  );
}

export default App;
