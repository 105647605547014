import React, { Component, useState } from 'react';

import Slider from "./components/Slider";
import Textbox from './components/Textbox';

function ChoiceQuestion(props)
{
    const [selected, setSelected] = useState({type: undefined, value: undefined});
    const [tbvalue, setTbValue] = useState("");

    const onSelected = (e, delay) => {
        if (e.value === "")
            return;
        
        setSelected(e);
        props.onNext(e.value, delay);
    }

    return (
        <div className="button-anwser-list">
            {props.data.values.map((e, i) => 
                <button 
                    key={i} 
                    className={"btn list-elem " + (selected.value === e.value ? "selected" : "") }
                    onClick={() => onSelected({type: "btn", value: e.value}, 1500)}>{e.text}</button>
            )}
            {props.data.otherText !== undefined && 
                    <Textbox 
                        className={"list-elem " + (tbvalue && selected.value === tbvalue ? "selected" : "")}
                        data={props.data} 
                        value={tbvalue}
                        onFocus={e => {props.onEditing(); setSelected({type: "tb", value: e.value})}}
                        onChange={e => {setTbValue(e.value); props.onEditing()}}
                        onValidated={e => {onSelected({type: "tb", value: e.value}, 0)}}
                        />}
        </div>
    )
}

function RadioQuestion(props)
{
    const [selected, setSelected] = useState({type: undefined, value: undefined});

    const onSelected = (e, delay) => {
        if (e.value === "")
            return;
        
        setSelected(e);
        props.onNext(e.value, delay);
    }

    return (
        <div>
            {props.data.values.map((e, i) => 
                <button 
                    key={i} 
                    className={"radio " + (selected.value === e ? "selected" : "") }
                    onClick={() => onSelected({type: "btn", value: e}, 1500)}>{e}</button>
            )}
        </div>
    )
}

function SliderQuestion(props)
{
    return (
        <Slider 
            min={props.data.min} max={props.data.max} 
            minText={props.data.minText} maxText={props.data.maxText} 
            defaultValue={props.data.min + (props.data.max - props.data.min) / 2}
            onEditing={() => props.onEditing()}
            onNext={e => props.onNext(e, 1500)} 
            />
    );
}

function NumberQuestion(props)
{
    const [selected, setSelected] = useState(false);
    const [tbvalue, setTbValue] = useState("");

    const onChange = e => {
        if (isNaN(e.value) || (props.data.size && e.value.length > props.data.size))
            return;
        setTbValue(e.value);
        props.onEditing()
    }

    const onValidated = e => {
        if (e.value === "")
            return;
        props.onNext(e.value, 0);
    }

    return (
        <Textbox 
            className={"list-elem " + (tbvalue && selected ? "selected" : "")}
            data={props.data} 
            value={tbvalue}
            onFocus={e => {props.onEditing(); setSelected(true)}}
            onChange={onChange}
            onValidated={onValidated}
            />
    );
}

function TextQuestion(props)
{
    const [selected, setSelected] = useState(false);
    const [tbvalue, setTbValue] = useState("");

    const onChange = e => {
        if (props.data.size && e.value.length > props.data.size)
            return;
        setTbValue(e.value);
        props.onEditing()
    }

    const onValidated = e => {
        if (e.value === "")
            return;
        props.onNext(e.value, 0);
    }

    return (
        <Textbox 
            className={"list-elem " + (tbvalue && selected ? "selected" : "")}
            data={props.data} 
            value={tbvalue}
            onFocus={e => {props.onEditing(); setSelected(true)}}
            onChange={onChange}
            onValidated={onValidated}
            />
    );
}

class QuestionView extends Component {
    
    componentDidMount()
    {
        this.questionRef = React.createRef();
    }

    onNext = (e, delay) => {
        const value = e;
        if (this.props.onNext)
            this.props.onNext({
                qid: this.props.data.qid,
                value: value
                // question: this.props.data.question
            }, delay);
    }

    RenderReponseType = () => {
        switch (this.props.data.type)
        {
            case "text":
                return (<TextQuestion data={this.props.data} onNext={this.onNext} onEditing={this.props.onEditing}/>)
            case "number":
                return (<NumberQuestion data={this.props.data} onNext={this.onNext} onEditing={this.props.onEditing}/>)
            case "choice":
                return (<ChoiceQuestion data={this.props.data} onNext={this.onNext} onEditing={this.props.onEditing}/>)
            case "radio":
                return (<RadioQuestion data={this.props.data} onNext={this.onNext} onEditing={this.props.onEditing}/>)
            case "slider":
                return <SliderQuestion data={this.props.data} onNext={this.onNext} onEditing={this.props.onEditing}/>
            default:    
                return <Slider min={this.props.data.min} max={this.props.data.max} defaultValue={0} onChange={this.handleSlider} onNext={this.onNext} />
        }
    }

    render() {
        return (
            <div className={"view " + (this.props.visible ? "" : "hidden")}>
                <div ref={this.questionRef} className="container alignLeft">
                    <div>
                        <span className="qnumber">{this.props.vid}.</span>
                        <h1>{this.props.data.question}</h1>
                    </div>
                    {this.props.data.subtitle && <h2>{this.props.data.subtitle}</h2>}
                    {this.props.data.text && <p>{this.props.data.text}</p>}
                    <div>
                        <this.RenderReponseType />
                    </div>
                    <button className="link" onClick={this.props.onIgnore}>Passer cette question</button>
                </div>
            </div>
        )
    }
}

export default QuestionView;