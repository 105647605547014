import React, { Component } from 'react';

import "styles/slider.scss";

let fullColorsArray = {};

class Slider extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            value: props.defaultValue === undefined ? 5 : props.defaultValue,
            max: props.max === undefined ? 10 : props.max,
            min: props.min === undefined ? 0 : props.min,
            maxText: props.maxText,
            minText: props.minText,
            validated: false
        }

        this.slider = React.createRef();
        this.pointer = React.createRef();
        this.changed = false;

        this.colors = [
            "255 228 206",
            "255 220 212",
            "255 211 211",
            "255 205 217",
            "255 186 218",
            "255 180 225",
            "241 171 237",
            "227 167 239",
            "213 174 239",
            "198 170 236"
        ];

        this.fullColors = [];
        if (!fullColorsArray[this.state.max - this.state.min])
        {
            for (let i = this.state.min; i <= this.state.max; i++)
            {
                const color = this.getCorrectColor(i, this.state.max, this.state.min);
                this.fullColors.push(color);
            }
            fullColorsArray = {
                ...fullColorsArray, 
                [this.state.max - this.state.min]: this.fullColors
            }
        }
        else
        {
            this.fullColors = fullColorsArray[this.state.max - this.state.min];
        }
    }

    componentDidMount()
    {
        const value = parseInt(this.slider.current.value);
        const max = parseInt(this.slider.current.max);
        const min = parseInt(this.slider.current.min);

        this.updateSlider(value, max, min);
    }

    getCorrectColor = (value, max, min) => {
        // Attention il y a des maths la

        const percent = (value - min) / (max - min);

        const colors = this.colors;
        const numColors = colors.length - 1;

        const firstColorID = Math.floor(percent * numColors);
        const nextColorID = Math.ceil(percent * numColors);

        const colorTransitionFactor = percent * numColors - firstColorID;

        const firstRgb = colors[firstColorID].split(' ');
        firstRgb.forEach((c, i) => firstRgb[i] = parseInt(c));

        const nextRgb = colors[nextColorID].split(' ');
        nextRgb.forEach((c, i) => nextRgb[i] = parseInt(c));

        let rgb = [
            firstRgb[0] + (nextRgb[0] - firstRgb[0]) * colorTransitionFactor,       
            firstRgb[1] + (nextRgb[1] - firstRgb[1]) * colorTransitionFactor,       
            firstRgb[2] + (nextRgb[2] - firstRgb[2]) * colorTransitionFactor   
        ];

        return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
    }

    handleSlider = e => {
        const value = parseInt(e.target.value);
        const max = parseInt(e.target.max);
        const min = parseInt(e.target.min);

        this.updateSlider(value, max, min);
        this.setState({value});

        if (this.props.onChange)
            this.props.onChange(e.target);
        
        this.changed = true;
    }

    updateSlider = (value, max, min) => {
        const percent = (value - min) / (max - min);
        this.pointer.current.style.left = "calc(4px - 10px + " + percent + " * (100% - 20px))";
        this.slider.current.style.setProperty('--slider-color', this.fullColors[value - min]);
    }

    onNext = () => {
        if (this.props.onNext && this.changed)
        {
            this.props.onNext(this.state.value);
            this.setState({validated: true});
        }
    }

    onEditing = () => {
        this.setState({validated: false});
        this.props.onEditing();
    }

    render() { 
        return (
            <div className="slider-container">
                <input 
                    ref={this.slider} 
                    onMouseDown={this.onEditing} onTouchStart={this.onEditing} 
                    onMouseUp={this.onNext} onTouchEnd={this.onNext} 
                    type="range" 
                    min={this.state.min} max={this.state.max} value={this.state.value} 
                    className={"slider " + (this.state.validated ? "validated" : "")} 
                    onChange={this.handleSlider}></input>
                <div ref={this.pointer} className="slider-point"></div>
                {this.state.minText && <span className="min-text">{this.state.minText}</span>}
                {this.state.maxText && <span className="max-text">{this.state.maxText}</span>}
            </div>
        );
    }
}

export default Slider;