// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import "firebase/analytics";

// Add the Firebase products that you want to use
// import "firebase/auth";
import "firebase/firestore";
// import "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyA_1eYoT56R-RCrwk2hqRLmMB8i_DjMViQ",
    authDomain: "peps-1b1bf.firebaseapp.com",
    databaseURL: "https://peps-1b1bf.firebaseio.com",
    projectId: "peps-1b1bf",
    storageBucket: "peps-1b1bf.appspot.com",
    messagingSenderId: "565101659555",
    appId: "1:565101659555:web:f4b7911dba60eaa5da4616"
};

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

// const auth = firebase.auth();
const db = firebase.firestore();
// const functions = firebase.functions();

export { db };
export default firebase;