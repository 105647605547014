import React, { Component } from 'react';

import Logo from "assets/logos/medicina-logo-colo.svg";

import "styles/error.scss";

class ErrorView extends Component {
    state = {  }
    render() { 
        return (
            <div className="error">
                <div className="side-visual"></div>
                <div className="error-container">
                    <div className="content">
                        <img src={Logo} alt="Medicina"/>
                        <h1>Erreur 404</h1>
                        {/* <p>Capitalize on low hanging fruit to identify a ballpark value added activity to beta.</p> */}
                    </div>
                </div>
                <div className="contact">
                    <p>
                        Un problème ? <a href="#">Contactez-nous ></a>
                    </p>
                </div>
            </div>
        );
    }
}

export default ErrorView;